import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
// import { StaticQuery, graphql } from 'gatsby'
// import Img from 'gatsby-image'

const Jumbotron = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  width: 100%;
  padding-bottom: 20px;
  box-sizing: border-box;
  h1 {
    font-size: 42px;
  }
  @media only screen and (max-width: 767px) {
    h1 {
      font-size: 32px;
    }
  }
`;

const Wrapper = styled.div`
  max-width: 1100px;
  padding: 70px 0;
  margin: 0 auto;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;

  img {
    width: 100%;
  }

  p {
    font-size: 20px;
    line-height: 26px;
  }

  .Image1 {
    width: 450px;
    float: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }
  .Image2 {
    width: 450px;
    float: right;
    margin-left: 20px;
    margin-bottom: 60px;
  }
  .quote {
    font-family: 'Yellowtail', cursive;
    font-weight: 400;
    font-size: 42px;
    line-height: 63px;
    margin: 80px 60px 80px 60px;
  }
  .hashtag {
    font-weight: 700;
  }
  .quotationMark {
    font-size: 48px;
  }
  .BowlImage {
    margin-top: 60px;
    width: 100%;
  }
    @media only screen and (max-width: 767px) {
      .quote {
        font-size: 26px;
        line-height: 39px;
        margin: 80px 60px 80px 60px;
      }
      .Image1 {
        width: 100%;
        float: none;
        margin: 0;
        margin-bottom: 20px;
      }
      .Image2 {
        width: 100%;
        float: none;
        margin: 0;
        margin-bottom: 20px;
      }
      .quotationMark {
        font-size: 36px;
      }
    }
`;

const GooglePurchasePage = () => (
  // <StaticQuery
  //   query = {graphql`
  //     query {
  //       dykesRoadOne: file(relativePath: { eq: "dykes_rd_1.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 750, quality: 80) {
  //             ...GatsbyImageSharpFluid
  //             presentationWidth
  //           }
  //         }
  //       }
  //       dykesRoadTwo: file(relativePath: { eq: "dykes_rd_2.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 750, quality: 80) {
  //             ...GatsbyImageSharpFluid
  //             presentationWidth
  //           }
  //         }
  //       }
  //       dicedBowls: file(relativePath: { eq: "DICED-Photo-bowls-min.png" }) {
  //         childImageSharp {
  //           fluid(maxWidth: 750, quality: 80) {
  //             ...GatsbyImageSharpFluid
  //             presentationWidth
  //           }
  //         }
  //       }
  //   }`}
  //   render = {data =>
      <Layout>
        <SEO 
        title="Diced Rd changed to Dykes Rd, After Google Purchase" 
        description='After very strong local response and re-appraisal of local objectives, Google has bought back DICED RD, formerly DYKES rd, and renamed it DYKES Rd.' 
        author='Diced Food'
        type='article'
        url='https://news.dicedfood.com/google-purchase'
        imageUrl='https://news.dicedfood.com/google-dykesrd.jpeg'
        keywords={[`healthy, food, dicedfood, Dykes Rd, Pembroke Pines`]} 
        lang='EN'  />

        <Wrapper>
          <Jumbotron>
                <h1>DICED RD CHANGED TO DYKES RD, AFTER GOOGLE PURCHASE.</h1>
          </Jumbotron>
          <div className='Image1'>
            {/* <Img alt='Diced Rd Street Sign' fluid={data.dykesRoadOne.childImageSharp.fluid} /> */}
            <img alt='Dykes Rd Street Sign' src='/dykes_rd_1.jpg' />
          </div>

          <p style={{marginTop: '0'}}>After very strong local response and re-appraisal of local objectives, Google has bought back DICED RD, formerly DYKES rd, and renamed it DYKES Rd. Google data, and sentiment algorithms raised a red flag at Google headquarters triggering the purchase.</p>
          <p>“Our sentiment algorithm over-indexed on protest language, and after taking a closer look we found that it was due to Pembroke Pines’ reaction to DICED Rd.” says Google representative Green Bowler. The buy back also has made it much easier for Pembroke Pines city officials to save time and labor on DICED Rd street signs.</p>
          <p> When reached for comment Diced CEO Otto Perez had this to say,</p>
          <p className='quote'><span className='quotationMark'>“</span>Despite the controversy I'm just excited to be here in Pembroke Pines. We've got a brand new shop on Dykes road, with a great new menu and a really friendly staff.  It was fun while it lasted. I’ll never regret my PembrokePinesProud tattoo and bringing the best food you can trust to Pembroke Pines.<span className='quotationMark'>”</span></p>

          <div className='Image2'>
            {/* <Img alt=' Diced Rd Street Sign' fluid={data.dykesRoadTwo.childImageSharp.fluid} /> */}
            <img alt='Diykes Rd Street Sign' src='/dykes_rd_2.jpg' />
          </div>

          <p style={{marginTop: '0'}}>It’s important to note the DYKES Rd was never bought and renamed in the first place. It’s been a friendly neighborhood gag by the DICED marketing team and we appreciate everyone who has made this such an enjoyable experience. Show this article at purchase for 20% off until June 30th.</p>

          {/* <Img className='BowlImage' alt=' Bowls' fluid={data.dicedBowls.childImageSharp.fluid} /> */}
          <img alt='Bowls of Food' src='/DICED-Photo-bowls-min.jpg' />
        </Wrapper>
      </Layout>
  //   }
  // />
)
export default GooglePurchasePage
